


































import {Component, Prop, Vue} from "vue-property-decorator";
import Workspace from "@/dto/auth/Workspace";
import {namespace} from "vuex-class";
import DeleteConfirmation from "@/components/payroll/business/DeleteConfirmation.vue";

const Auth = namespace("Auth");
const AppModule = namespace("App");

@Component
export default class DocumentActionModal extends Vue {

    @AppModule.Action
    private startLoading!: () => void

    @AppModule.Action
    private stopLoading!: () => void

    private successful = false;

    private message = "";

    @Prop()
    private onDelete!: () => void

    @Prop()
    private onDownload!: () => void


    closeModal() {
        this.$modal.hideAll()
    }

    deleteDocument() {
        this.closeModal();
        this.$modal.show(
            DeleteConfirmation,
            {
              onDelete: this.onDelete,
              targetName: "the document"
            }
        )
    }

    downloadDocument() {
        this.onDownload();
        this.closeModal();
    }
}
